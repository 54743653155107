import React from 'react'
import Slider from 'react-slick'
import { Link } from 'gatsby';
import { CustomPrevArrow, CustomNextArrow } from '../Slick/CustomArrows'
import Image from '../Images'
import Style from '../../styles/components/banner.home.module.scss'

export default class extends React.Component {
  render() {
    const { items } = this.props;
    const settings = {
      prevArrow: <CustomPrevArrow type="banner" />,
      nextArrow: <CustomNextArrow type="banner" />,
      autoplay: this.props.env !== 'development',
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
      autoplaySpeed: 6000,
      dots: true
    };

    return (
      <Slider {...settings} className="home-banner twic-view" style={{ marginTop: 0 }}>
        {items.filter(i => i.src !== null).map((item, index) => {
          const bannerUrl = item.src.replace('images.prismic.io', 'wakatoon.cdn.prismic.io').replace('%2F', '/');
          return (
            <div key={index}>
              <picture>
                <source media="(max-height: 414px)" srcSet={`${this.props.twic}/auto/${item.focus ? `focus=${item.focus}p/` : ''}cover=21:9/resize=800/${bannerUrl} 800w, ${this.props.twic}/auto/${item.focus ? `focus=${item.focus}p/` : ''}cover=21:9/resize=1000/${bannerUrl} 1000w, ${this.props.twic}/auto/${item.focus ? `focus=${item.focus}p/` : ''}cover=21:9/resize=1200/${bannerUrl} 1200w`} />
                <source media="(max-width: 767px)" srcSet={`${this.props.twic}/auto/${item.focus ? `focus=${item.focus}p/` : ''}cover=1:1/resize=300/${bannerUrl} 300w, ${this.props.twic}/auto/${item.focus ? `focus=${item.focus}p/` : ''}cover=1:1/resize=500/${bannerUrl} 500w, ${this.props.twic}/auto/${item.focus ? `focus=${item.focus}p/` : ''}cover=1:1/resize=700/${bannerUrl} 900w`} />
                <source media="(min-width: 992px)" srcSet={`${this.props.twic}/auto/cover=21:9/resize=1000/${bannerUrl} 1000w, ${this.props.twic}/auto/cover=21:9/resize=1500/${bannerUrl} 1500w, ${this.props.twic}/auto/cover=21:9/resize=1900/${bannerUrl} 1900w`} />
                <source media="(min-width: 768px)" srcSet={`${this.props.twic}/auto/${item.focus ? `focus=${item.focus}p/` : ''}cover=4:3/resize=800/${bannerUrl} 800w, ${this.props.twic}/auto/${item.focus ? `focus=${item.focus}p/` : ''}cover=4:3/resize=1000/${bannerUrl} 1000w, ${this.props.twic}/auto/${item.focus ? `focus=${item.focus}p/` : ''}cover=4:3/resize=1200/${bannerUrl} 1200w`} />
                <Link className={Style.titleLink} to={item.link}>
                  <Image src={bannerUrl} alt={item.alt} />
                </Link>
              </picture>
              <div className={Style.items}>
                <div className={Style.wrapper}>
                  <p>
                    <Link className={Style.titleLink} to={item.link}>{item.alt}</Link>
                  </p>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    );
  }
}
