import React from 'react';
import { connect } from 'react-redux'
import { graphql, Link } from 'gatsby';
import * as moment from 'moment'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'
import Style from '../styles/pages/blog.module.scss'
import BlogBanner from '../components/Banners/BlogBanner';
import Image from '../components/Images'

class Blog extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      filter: null
    }
    
    // posts ordered by publication date DESC
    this.posts = props.data.posts.edges.map((i) => {
      const node = i.node;
      const data = node.data;
      const publication_date = data.publication_date || moment(node.first_publication_date, "YYYY-MM-DDTHH:mmZ").format("YYYY-MM-DD")
      
      return {
        data: data,
        lang: node.lang,
        uid: node.uid,
        publication_date: publication_date,
        timestamp: moment(publication_date).format('x')
      }
    }).sort((a, b) => b.timestamp - a.timestamp);

    // featured posts
    this.featured = this.posts.filter(i => i.data.featured_content === 'true');

    // banners
    this.banners = this.featured.map(i => { 
      return {
        alt: i.data.title.text,
        src: i.data.header.url,
        focus: i.data.focus,
        link: `/fr/blog/${i.data.category.uid}/${i.uid}/`
      }
    });
  }

  setFilter = (filter) => {
    this.setState({ filter: filter });
  }

  _t = (str) => {
    const _t = this.props.data.staticText.edges[0].node.data.texts;
    return _t[_t.findIndex(item => item.key_text === str)].text.text;
  };

  render() {
    const {
      data: { blogCategories, staticText, bottomBackground },
      pageContext
    } = this.props;
    
    const staticPrismicTexts = staticText.edges[0].node.data.texts;
    const colorConversion = {
      '#8c86d1': '_purple',
      '#ef6c85': '_pink',
      '#5fc090': '_green',
      '#00abe7': '_blue',
      '#f2bd12': '_yellow',
    };
    
    const posts = this.state.filter ? (
      this.posts.filter((item) => item.data.category.uid === this.state.filter)
    ) : this.posts;

    return (
      <Layout 
        context={pageContext} 
        pageTitle={this._t(`Wakatoon actualités`)}
        _t={staticPrismicTexts}
      >
        <SEO 
          pageTitle={this._t(`L'actualité du coloriage animé | Blog Wakatoon`)} 
          pageDescription={this._t(`Présentation des coulisses de la start-up qui a inventé les livres de coloriages qui se transforment en un dessin animé.`)} 
          context={pageContext}
        />
        <div 
          className="page" 
          style={{
            background: `url(${bottomBackground.edges[0].node.publicURL})`,
            backgroundPosition: `bottom center`,
            backgroundRepeat: `no-repeat`,
            paddingBottom: `100px`
          }}
        >
          <div className={Style.hero}>
            <BlogBanner items={this.banners} twic={pageContext.twic} />
          </div>
          <section style={{background: "white", paddingBottom: 0}}>
            <div className="page-head no-padding">
              <h2>{this._t(`Wakatoon actualités`)}</h2>
              <div className={`${Style.categories}`}>
                {
                  blogCategories.edges.map((item, index) => {
                    return (
                      <button 
                        key={`blog-category-${index}`} 
                        className={`button btn btn_default ${(this.state.filter && this.state.filter === item.node.uid) ? Style.active : ''}`}
                        style={{
                          boxShadow: `4px 4px 0 2px ${item.node.data.color}`,
                          border: `2px solid ${item.node.data.color}`,
                          color: `${item.node.data.color}`
                        }}
                        onClick={() => this.state.filter === item.node.uid ? this.setFilter(null) : this.setFilter(item.node.uid)}
                      >
                        {item.node.data.category_name}
                      </button>
                    )
                  })
                }
              </div>
            </div>
          </section>

          <section style={{paddingTop: 0, paddingBottom: 100}}>
            {
              posts && (
                <div className="container">
                  <div className={`row`}>
                      {
                        posts.map((item,index) => {
                          return (
                            <div className={`col-4 ${Style.posts} ${item.data.category.uid}`} key={`post-${index}`}>
                              <div className={`${Style.postContainer}`}>
                                <div>
                                  <div className={Style.thumbnail}>
                                    {
                                      item.data.thumbnail ? (
                                        <Image src={item.data.thumbnail.url} alt={item.data.meta_title} />
                                      ) : (
                                        <Image src={item.data.header.url} alt={item.data.meta_title} />
                                      )
                                    }
                                  </div>
                                  <div className={Style.contents}>
                                    <span style={{ color: `${item.data.category.document[0].data.color}`}}>
                                      {item.data.category.document[0].data.category_name}
                                    </span>
                                    <h3>{item.data.title.text}</h3>
                                    <p>
                                      {item.data.excerpt}
                                    </p>
                                  </div>
                                </div>
                                <Link 
                                  key={`blog-category-${index}`} 
                                  className={`button btn btn_default ${colorConversion[item.data.category.document[0].data.color]}`}
                                  to={`/fr/blog/${item.data.category.uid}/${item.uid}/`}
                                >
                                  {this._t(`Lire la suite`)}
                                </Link>
                              </div>
                            </div>
                          )
                        })
                      }
                  </div>
                </div>
              )
            }
          </section>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.locale
});
export default connect(mapStateToProps)(Blog);
export const pageQuery = graphql`
  query BlogPosts($locale: String!) {
    posts: allPrismicBlogPost(
      sort: { fields: first_publication_date, order: DESC },
      filter: { lang: {eq: $locale}}
    ){
      edges {
        node {
          lang
          uid
          first_publication_date
          data {
            meta_title
            title {
              text
            }
            publication_date
            excerpt
            content {
              text
            }
            header {
              url
            }
            focus
            thumbnail {
              url
            }
            featured_content
            category {
              uid
              document {
                data {
                  category_name
                  color
                }
              }
            }
          }
        }
      }
    }
    blogCategories: allPrismicBlogCategory {
      edges {
        node {
          data {
            category_name
            color
          }
          uid
        }
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
    bottomBackground: allFile(filter: {relativePath: {eq: "Blog_crayons_home.png"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;
